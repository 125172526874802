import React, { useEffect } from 'react';
import { BoxLoading } from '../components/LoadingSuspense';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

function RedirectingToLogin() {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <BoxLoading>
      <p>{t('Redirecting to login...')}</p>
    </BoxLoading>
  );
}

export default RedirectingToLogin;
