import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { colorPalette, LineOfBusiness, TextLabel, Typo } from 'ri-components';
import PropTypes from 'prop-types';
import { PaginatedClaimProperties } from '../../enums/PaginatedClaimProperties';
import { useHistory } from 'react-router-dom';
import { useHandlerClientConfig } from '../../pages/handler-dashboard/HandlerClientConfigContextProvider';

const { grey, coolGrey } = colorPalette;

const useStyles = makeStyles((theme) => ({
  labelsCotnainer: {
    order: 1,
    marginBottom: '8px',
    '& > *:first-child': {
      marginRight: '4px',
    },
    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginBottom: '0px',
      '& > *:first-child': {
        marginLeft: 'auto',
      },
    },
  },
  nameContainer: {
    order: 2,
    marginBottom: '4px',
    [theme.breakpoints.up('sm')]: {
      order: 1,
      marginBottom: '8px',
    },
  },
  submissionRef: {
    flexDirection: 'column',
    '& > *': {
      flexBasis: 'auto',
      display: 'flex',
    },
    '& > *:first-child': {
      marginBottom: '4px',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& > *:first-child': {
        marginBottom: '0px',
        marginRight: '16px',
      },
    },
  },
  spacingContainer: {
    marginBottom: '4px',
  },
  muttedLabel: {
    marginRight: '4px',
  },
  resultClaimContainer: {
    padding: '16px 20px',
    cursor: 'pointer',
    transition: '200ms',
    '&:hover': {
      backgroundColor: grey[2],
    },
  },
}));

function SearchResultClaim({ claim, onCloseModal }) {
  const classes = useStyles();
  const history = useHistory();

  const onClickClaim = (ev) => {
    onCloseModal(ev, true);
    history.push(`/claim/${claim.id}/overview`);
  };

  const { clientShortName } = useHandlerClientConfig();

  return (
    <Grid container className={classes.resultClaimContainer} onClick={onClickClaim} role='option'>
      <Grid container>
        <Grid container item className={classes.labelsCotnainer} xs={12} sm={6}>
          <Grid item>
            <LineOfBusiness lineOfBusiness={claim.lineOfBusiness} />
          </Grid>
          <Grid item>
            <TextLabel bgcolor={coolGrey[2]} color={coolGrey[9]}>
              {clientShortName}
            </TextLabel>
          </Grid>
        </Grid>
        <Grid container item className={classes.nameContainer} xs={12} sm={6}>
          <Typo variant='bodyLg' fontWeight='medium'>
            {claim.claimantName}
          </Typo>
        </Grid>
      </Grid>
      <Grid container className={classes.spacingContainer} direction='column'>
        <Grid item className={classes.spacingContainer}>
          <Typo variant='bodySm'>{claim.claimantEmail}</Typo>
        </Grid>
        <Grid item>
          <Typo variant='bodySm'>{claim.claimantPhoneNumber}</Typo>
        </Grid>
      </Grid>
      <Grid container className={classes.submissionRef}>
        <Grid item>
          <Typo variant='bodySm' color='muted' className={classes.muttedLabel}>
            Submission Ref
          </Typo>
          <Typo variant='bodySm'>{claim.submissionReference}</Typo>
        </Grid>
        <Grid item>
          <Typo variant='bodySm' color='muted' className={classes.muttedLabel}>
            Policy No
          </Typo>
          <Typo variant='bodySm'>{claim.policyNumber}</Typo>
        </Grid>
      </Grid>
    </Grid>
  );
}

SearchResultClaim.propTypes = {
  claim: PropTypes.shape(PaginatedClaimProperties).isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default SearchResultClaim;
