import { useEffect, useState } from 'react';

function useDebounce(value, delay = 300, cb = null) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVal(value);
      cb && cb(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [cb, delay, value]);

  return val;
}

export default useDebounce;
