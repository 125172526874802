import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0Configs } from '../services/ApiConfigsContextProvider';

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const auth0Config = useAuth0Configs();

  useEffect(() => {
    const getUserDetails = async () => {
      if (!isAuthenticated || !user?.sub) return;

      const audience = `https://${auth0Config.domain}/api/v2/`;
      const scope = "read:current_user";

      let accessToken = await getAccessTokenSilently({
        authorizationParams: { audience, scope, prompt: "none" },
      });

      const userDetailsByIdUrl = `https://${auth0Config.domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (metadataResponse.status === 401) {
        throw new Error("Token expired or invalid.");
      }

      if (!metadataResponse.ok) {
        throw new Error(`Failed to fetch user details: ${metadataResponse.statusText}`);
      }

      const userResponse = await metadataResponse.json();
      setUserDetails(userResponse);
    };

    getUserDetails();
  }, [getAccessTokenSilently, user?.sub, isAuthenticated, auth0Config.domain]);

  const memoizedUserDetails = useMemo(() => userDetails, [userDetails]);

  return (
    <UserContext.Provider value={memoizedUserDetails}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
