import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import Navbar from '../../components/navbar/Navbar';
import { useCreateDefaultHandlerTheme } from './../../themes/default-handler-theme';
import { withHandlerClientConfigs } from './HandlerClientConfigContextProvider';

const HandlerLayout = ({ children }) => {
  const defaultHandlerTheme = useCreateDefaultHandlerTheme();
  return (
    <ThemeProvider theme={defaultHandlerTheme}>
      <Navbar />
      {children}
    </ThemeProvider>
  );
};

HandlerLayout.propTypes = {
  children: PropTypes.node,
};

export default withHandlerClientConfigs(HandlerLayout);
