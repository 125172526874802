import { createTheme } from '@material-ui/core';
import { colorPalette } from 'ri-components';
import { useHandlerClientConfig } from '../pages/handler-dashboard/HandlerClientConfigContextProvider';
import { globalTypography } from './GlobalThemeProvider';

const { white, primary, grey } = colorPalette;

export const defaultHandlerThemeOptions = {
  spacing: 8,
  palette: {
    background: {
      default: white,
    },
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
    },
    text: {
      primary: grey[10],
      secondary: grey[8],
    },
    action: {
      active: grey[8],
    },
  },
  typography: {
    ...globalTypography,
    body1: {
      letterSpacing: '0.1px',
      fontSize: '15px',
    },
    h6: {
      fontSize: '17px',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1020,
      xl: 1200,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: white,
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
    },
  },
};

export const defaultHandlerTheme = createTheme(defaultHandlerThemeOptions);

export const useCreateDefaultHandlerTheme = () => {
  const clientConfigs = useHandlerClientConfig();
  const theme = createTheme(defaultHandlerThemeOptions, {
    palette: {
      primary: {
        main: clientConfigs.primaryColour ?? primary.main,
      },
    },
  });
  return theme;
};
