import React from 'react';
import AuthenticatedContext from '../../auth/AuthenticatedContext';
import TenantPermissionProvider from '../../services/TenantPermissionProvider';
import HandlerLayout from './HandlerLayout';
import LoadingSuspense from '../../components/LoadingSuspense';
import RouteWrapper from '../../components/RouteWrapper';
import TenantPermissions from '../../enums/TenantPermissions';
import { Redirect } from 'react-router-dom';

const HandlerDashboard = React.lazy(() => import('./HandlerDashboard'));
const ClaimNavigation = React.lazy(() => import('../claim-navigation/ClaimNavigation'));

function HandlerRoutesWrapper() {
  return (
    <TenantPermissionProvider permission={TenantPermissions.Handler}>
      <AuthenticatedContext>
          <HandlerLayout>
            <LoadingSuspense>
              <Redirect exact from='/' to='/dashboard' />
              <RouteWrapper exact path='/dashboard' component={HandlerDashboard} />
              <RouteWrapper exact path='/claim/:id/:page' component={ClaimNavigation} />
            </LoadingSuspense>
          </HandlerLayout>
      </AuthenticatedContext>
    </TenantPermissionProvider>
  );
}

export default HandlerRoutesWrapper;
