import React, { createContext, useContext, useState, useEffect } from 'react';
import { BoxLoading } from '../components/LoadingSuspense';
import PropTypes from 'prop-types';
import appendLink from '../utils/appendLink';

function useApiAppConfigs() {
  const [isLoading, setIsLoading] = useState(true);
  const [configs, setConfigs] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch('api/app-config');
      const result = await response.json();
      appendLink({
        rel: 'preconnect',
        href: result.webApiUrl,
      });
      setConfigs(result);
      setIsLoading(false);
    })();
  }, []);

  return { isLoading, configs };
}

export const ApiConfigsContext = createContext(null);
export const useApiConfigs = () => useContext(ApiConfigsContext);
export const useAuth0Configs = () => useContext(ApiConfigsContext)?.auth0Configs;

export const withApiConfigs = (Component) => {
  return (props) => {
    const configs = useApiConfigs();
    return <Component webApiUrl={configs.webApiUrl} apiConfigs={configs} {...props} />;
  };
};

function ApiConfigsContextProvider({ children }) {
  const { isLoading, configs } = useApiAppConfigs();

  return (
    <ApiConfigsContext.Provider value={configs}>
      {isLoading ? <BoxLoading /> : children}
    </ApiConfigsContext.Provider>
  );
}

ApiConfigsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiConfigsContextProvider;
