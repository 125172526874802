import PropTypes from 'prop-types';
import { LineOfBusinessTypes } from 'ri-components';

export const PaginatedClaimProperties = Object.freeze({
  id: PropTypes.string,
  conversationFileId: PropTypes.string,
  claimantName: PropTypes.string,
  incidentType: PropTypes.string,
  lineOfBusiness: PropTypes.oneOf(Object.values(LineOfBusinessTypes)),
  submittedDate: PropTypes.string,
  status: PropTypes.string,
  claimantPhoneNumber: PropTypes.string,
  policyNumber: PropTypes.string,
  claimantEmail: PropTypes.string,
  submissionReference: PropTypes.string,
});
