import '../themes/styles/styles.css';

export function disableBodyScrolling() {
  document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
  document.body.classList.add('disable-scrolling');
}

export function enableBodyScrolling() {
  document.body.style.paddingRight = '0px';
  document.body.classList.remove('disable-scrolling');
}
