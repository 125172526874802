import { useMemo } from 'react';
import { useFetcher } from '../../hooks/useFetcher';
import { useApiConfigs } from '../../services/ApiConfigsContextProvider';
import { useInfiniteQuery } from 'react-query';

const SEARCH_CONTAINER_FILTER_FIELDS =
  'policyNumber,claimantName,submissionReference,claimantPhoneNumber,claimantEmail';

export const useNavSearch = (searchBarText) => {
  const filters = useMemo(
    () => [{ filterValues: [searchBarText], filterFields: SEARCH_CONTAINER_FILTER_FIELDS }],
    [searchBarText],
  );
  const fetcher = useFetcher();
  const { webApiUrl } = useApiConfigs();

  return useInfiniteQuery(
    `navbarsearch-${searchBarText}`,
    ({
      pageParam = {
        page: 1,
        pageSize: 10,
        filters,
      },
    }) => fetcher(`${webApiUrl}/api/claim/paged`, { method: 'POST', body: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        const itemsDisplayedCount = (lastPage.page - 1) * lastPage.pageSize + lastPage.result?.length;
        if (itemsDisplayedCount >= lastPage.totalCount) return undefined;
        const nextPage = {
          ...lastPage,
          page: lastPage.page + 1,
          filters,
        };
        return nextPage;
      },
      enabled: searchBarText.length > 1,
    },
  );
};
