import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { withApiConfigs } from '../../services/ApiConfigsContextProvider';
import useHttpRequest from '../../hooks/useHttpRequest';

export const ClientConfigContext = createContext(null);
export const useHandlerClientConfig = () => useContext(ClientConfigContext);

function HandlerClientConfigContextProvider({ children, webApiUrl }) {
  const { data: clientConfig } = useQuery(`${webApiUrl}/api/client-config`, { suspense: true });
  const { sendRequest, data: clientAssetsUrl } = useHttpRequest();
  const [primaryColor, setPrimaryColor] = useState(null);

  useEffect(() => {
    sendRequest({ url: '/api/client-config/client-assets-url' });
  }, [sendRequest]);

  useEffect(() => {
    const cssId = 'tenantCSS';

    if (!document.getElementById(cssId) && clientConfig?.id && clientAssetsUrl) {
      const cssElement = addCss(cssId, clientAssetsUrl, clientConfig.id);
      cssElement.onload = () => {
        const styles = getComputedStyle(cssElement);
        const mainColor = getStyleVariable(styles, '--primary-main-color');
        setPrimaryColor(mainColor);
      };
    }
  }, [clientAssetsUrl, clientConfig]);

  const clientConfigs = { ...clientConfig, primaryColour: primaryColor };

  return <ClientConfigContext.Provider value={clientConfigs}>{children}</ClientConfigContext.Provider>;
}

const getStyleVariable = (styles, name) => {
  const result = styles.getPropertyValue(name);
  if (result) return result.trim();
  return null;
};

const addCss = (cssId, clientAssetsUrl, clientId) => {
  const head = document.getElementsByTagName('head')[0];
  const linkElement = document.createElement('link');

  linkElement.id = cssId;
  linkElement.rel = 'stylesheet';
  linkElement.type = 'text/css';
  linkElement.href = `${clientAssetsUrl}/${clientId.toLowerCase()}.css`;
  linkElement.media = 'all';

  head.appendChild(linkElement);
  return linkElement;
};

HandlerClientConfigContextProvider.propTypes = {
  children: PropTypes.node,
  webApiUrl: PropTypes.string,
  tenantId: PropTypes.string,
};

export const withHandlerClientConfigs = (Component) => {
  const Wrapper = withApiConfigs(HandlerClientConfigContextProvider);

  return (props) => (
    <Wrapper>
      <Component {...props} />
    </Wrapper>
  );
};

export default withApiConfigs(HandlerClientConfigContextProvider);
