import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Input, INPUT_SIZES } from 'ri-components';
import { disableBodyScrolling, enableBodyScrolling } from '../../utils/bodyScrolling';
import SearchResults from './SearchResults';
import { ClickAwayListener } from '@material-ui/core';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles({
  searchGroup: {
    width: '100%',
    maxWidth: '480px',
    position: 'relative',
  },
});

function SearchContainer({ isDisplayModal, setIsDisplayModal }) {
  const classes = useStyles();
  const [searchBarText, setSearchBarText] = useState('');
  const isSearchTextEmpty = !(searchBarText && searchBarText.trim());

  const debouncedSearchText = useDebounce(searchBarText, 300);

  const [leftInputWidth, setLeftInputWidth] = useState(0);
  const inputRef = useRef(null);

  const onChangeInput = (ev) => {
    const val = ev.target.value;
    setSearchBarText(val);
    if (val.length > 1 && !isDisplayModal) {
      setIsDisplayModal(true);
      disableBodyScrolling();
    } else if (val.length < 2 && isDisplayModal) {
      setIsDisplayModal(false);
      enableBodyScrolling();
    }
  };

  const clearInputText = (ev) => onClickAway(ev, true);

  const onFocusInput = () => {
    if (searchBarText.length > 1 && !isDisplayModal) {
      setIsDisplayModal(true);
      disableBodyScrolling();
    }
  };

  const onClickAway = (ev, isClearingInput) => {
    ev.preventDefault();
    if (isClearingInput) {
      setSearchBarText('');
    }
    setIsDisplayModal(false);
    enableBodyScrolling();
  };

  useEffect(() => {
    function onResize() {
      const input = inputRef.current;
      const { left } = input.getBoundingClientRect();
      setLeftInputWidth(left);
    }
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    if (isSearchTextEmpty) {
      setIsDisplayModal(false);
      enableBodyScrolling();
    }
  }, [isSearchTextEmpty, setIsDisplayModal]);

  return (
    <ClickAwayListener
      mouseEvent={isDisplayModal ? 'onClick' : false}
      touchEvent={isDisplayModal ? 'onTouchEnd' : false}
      onClickAway={onClickAway}
    >
      <div className={classes.searchGroup} data-testid='nav-search-container'>
        <Input
          placeholder='Search claims'
          startIcon={<SearchIcon fontSize='small' color='action' />}
          endIcon={
            !isSearchTextEmpty && <ClearIcon onClick={clearInputText} fontSize='small' color='action' />
          }
          fullWidth
          value={searchBarText}
          onChange={onChangeInput}
          className={classes.searchInput}
          zIndex='6'
          onFocus={onFocusInput}
          role='search'
          aria-label='Search for claims'
          round
          size={INPUT_SIZES.small}
          innerRef={inputRef}
        />
        {!isSearchTextEmpty && isDisplayModal && (
          <SearchResults
            searchBarText={debouncedSearchText}
            isDisplayModal={isDisplayModal}
            onCloseModal={onClickAway}
            leftInputWidth={leftInputWidth}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}

SearchContainer.propTypes = {
  isDisplayModal: PropTypes.bool.isRequired,
  setIsDisplayModal: PropTypes.func.isRequired,
};

export default SearchContainer;
