import { useEffect, useRef } from 'react';

export default function useInView(options) {
  const { isPreventing, root, rootMargin, threshold, onIntersecting } = options;
  const ref = useRef(null);
  useEffect(() => {
    let observer = null;
    if (isPreventing && ref.current) {
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersecting();
            observer.disconnect();
          }
        });
      };
      const options = {
        root: root ?? null,
        rootMargin: rootMargin ?? '0px',
        threshold: threshold ?? 0,
      };

      observer = new IntersectionObserver(callback, options);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [onIntersecting, ref, isPreventing, root, rootMargin, threshold]);

  return { ref };
}

export const isLastElement = (parentList, pIdx, nestedList, nIdx) => {
  return pIdx !== parentList.length - 1 || (pIdx === parentList.length - 1 && nIdx !== nestedList.length - 1);
};

export const isEmptySearch = (data) => {
  return data?.pages && Array.isArray(data.pages) && data.pages[0] && data.pages[0].result?.length === 0;
};
